import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RootLayout from "../../component/layout";
import OtpForm from "../../component/auth/OtpForm";

const VerifyOtp = () => {
  const customerData = useSelector((state) => state?.reducer?.customerData);
  const navigate = useNavigate();

  useEffect(() => {
    if (!customerData) {
      navigate("/auth/signin");
    }
  }, [customerData, navigate]);
  return (
    <RootLayout>
      <OtpForm />
    </RootLayout>
  );
};

export default VerifyOtp;
