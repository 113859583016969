import accounting from "accounting";
import Button from "../common/Button";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const ConfirmationCrypto = () => {
    const socket = useSelector(state => state.socket);
    const paymentObj = useSelector(state => state?.reducer?.checkoutConfig);
    const [countdown, setCountdown] = useState(null);
    const [matchedMessage, setMatchedMessage] = useState(null); // New state to track matched message


    useEffect(() => {
        // Check if there's a matching message with success status
        const successfulMessage = socket?.messages.find(
            message => message?._id === paymentObj?._id && message?.status === "success"
        );

        // Start countdown when a matching message is found and countdown hasn't started
        if (successfulMessage && countdown === null) {
            setMatchedMessage(successfulMessage);
            setCountdown(5);
        }
    }, [socket?.messages, countdown, paymentObj]);

    useEffect(() => {
        if (countdown !== null && countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        } else if (countdown === 0 && matchedMessage) {
            // Redirect only if there's a matched successful message
            window.location.assign(paymentObj?.successCallback + "?paymentId=" + paymentObj?._id);
        }
    }, [countdown, matchedMessage, paymentObj]);

     return (
        <div className="w-full mt-8">
            {socket?.messages?.length > 0 && <div className="transactions-box rounded-xl border border-gray pt-6 pb-[12px] px-4 w-full">
                <h3 className="common-lbl fw-bold">Transactions</h3>
                <div className="w-full">
                    {socket?.messages
                        .filter(message => message?._id === paymentObj?._id) // Filter messages where transactionId matches paymentObj._id
                        .map((message, index) => (
                            message?.status === "success" ? (
                                <div key={index} className="flex items-center justify-between py-4 border-b border-gray trx-row">
                                    <div className="flex items-center justify-start">
                                        <span className="common-lbl">
                                            {accounting.formatNumber(message?.amount, { precision: 2 })} {message?.currency}
                                        </span>
                                        <span className="w-[50px] h-[12px] flex items-center justify-center small-text-lbl bg-green text-white uppercase rounded-xl ml-4 trx-badge-lbl">{message?.status}</span>
                                    </div>
                                    <div className="flex items-end justify-end">
                                        <span className="small-text-lbl">{message?.confirmationCount}/{paymentObj?.crypto?.confirmationsRequired || "12"} Confirmations</span>
                                    </div>
                                </div>
                            ) : (
                                <div key={index} className="flex items-center justify-between py-4 border-b border-gray trx-row">
                                    <div className="flex items-center justify-start">
                                        <span className="common-lbl">
                                            {accounting.formatNumber(message?.amount, { precision: 2 })} {message?.currency}
                                        </span>
                                        <span className="w-[50px] h-[12px] flex items-center justify-center small-text-lbl bg-orange text-white uppercase rounded-xl ml-4 trx-badge-lbl">{message?.status}</span>
                                    </div>
                                    <div className="flex items-end justify-end">
                                        <span className="small-text-lbl">{message?.confirmationCount}/{paymentObj?.crypto?.confirmationsRequired || "12"} Confirmations</span>
                                    </div>
                                </div>
                            )
                        ))}
                </div>
            </div>}
            {matchedMessage ? (
                <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
                    <Button disabled={true}>
                        <span className="text-white fw-bold" > {countdown !== null
                            ? `Redirecting in ${countdown}...`
                            : "Redirecting to Site"}</span>
                    </Button>
                </div>
            ) : (
                <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
                    <Button
                        className="!flex items-center justify-center" disabled={true}>
                        <div className="circle-spin-loader btn-small-spinner"></div>
                        <span>Waiting Confirmations</span>
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ConfirmationCrypto;