
import * as ActionTypes from "../actionTypes"; // Import your action types
import { apiCall } from "../api";

export const getMudrexKycLink = (paymentId,key) => async (dispatch) => {
  try {
    // Make the API call to fetch organizations
    const response = await apiCall(`/sdk/mudrex/generate-kyc-link`, "POST", {paymentId},key);

    dispatch({
      type: ActionTypes.GENERATE_MUDREX_KYC_LINK,
      payload: response.kycLink,
    });

    return {
      code: 1,
      data: response.kycLink,
    };
  } catch (error) { 
    dispatch({
      type: ActionTypes.GENERATE_MUDREX_KYC_LINK_FAILURE,
      payload: error,
    });

    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
}

export const getMudrexPaymentMethods = (key) => async (dispatch) => {
  
  try {
    // Make the API call to fetch organizations
    const response = await apiCall(`/sdk/mudrex/payment-methods`, "GET", null,key);

    dispatch({
      type: ActionTypes.GET_MUDREX_PAYMENT_METHODS,
      payload: response,
    });

    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    dispatch({
      type: ActionTypes.GET_MUDREX_PAYMENT_METHODS_FAILURE,
      payload: error,
    });

    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
}

export const getMudrexPaymentIntent = (data,key) => async (dispatch) => {

  try {
    // Make the API call to fetch organizations
    const response = await apiCall(`/sdk/mudrex/generate-upi-intent`, "POST", data,key);

    dispatch({
      type: ActionTypes.GET_MUDREX_PAYMENT_INTENT,
      payload: response,
    });

    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    dispatch({
      type: ActionTypes.GET_MUDREX_PAYMENT_INTENT_FAILURE,
      payload: error,
    });

    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
}

export const getMudrexUserKyc = (paymentId,key) => async (dispatch) => {

  try {
    // Make the API call to fetch organizations
    const response = await apiCall(`/sdk/mudrex/get-user/${paymentId}`, "GET", null,key);

    dispatch({
      type: ActionTypes.GET_MUDREX_USER,
      payload: response,
    });

    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    dispatch({
      type: ActionTypes.GET_MUDREX_USER_FAILURE,
      payload: error,
    });

    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
}

export const getPaymentStatus = (paymentId,key) => async (dispatch) => {
  
  try {
    // Make the API call to fetch organizations
    const response = await apiCall(`/sdk/mudrex/get-transaction/${paymentId}`, "GET", null,key);

    dispatch({
      type: ActionTypes.GET_PAYMENT_STATUS,
      payload: response,
    });

    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    dispatch({
      type: ActionTypes.GET_PAYMENT_STATUS_FAILURE,
      payload: error,
    });

    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
}