import React from 'react'

const SidebarSummary = () => {
  return (
    <div className="w-full h-full flex flex-col items-start justify-center py-[10px]">
      <a href="/main/dashboard/" className="logo-link">
        <img
          src="/banksy-black-logo.svg"
          alt="logo"
          className="w-full logo-transparent-white"
        />
      </a>
      <p className="bnksy-page-title my-[20px] !text-left">
        Experience a safer and faster payment method with Banksy
      </p>
      <p className="common-lbl text-left mb-0">
        Wherever you shop, your financial <br /> data stays protected with us.
      </p>
    </div>
  )
}

export default SidebarSummary