import { createSlice } from '@reduxjs/toolkit';

const socketSlice = createSlice({
  name: 'socket',
  initialState: {
    messages: [],
    status: false,
  },
  reducers: {
    addMessage: (state, action) => {
      // Check if the payload has a defined _id
      if (action.payload._id !== undefined) {
        const existingMessageIndex = state.messages.findIndex(
          message => message._id === action.payload._id
        );
    
        if (existingMessageIndex !== -1) {
          // Update existing message
          state.messages[existingMessageIndex] = action.payload;
        } else {
          // Add new message if it doesn't already exist
          const isDuplicate = state.messages.some(
            message => message._id === action.payload._id
          );
          if (!isDuplicate) {
            state.messages.push(action.payload);
          }
        }
      }
      // If _id is undefined, do nothing
    },
    addStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { addMessage, addStatus } = socketSlice.actions;
export default socketSlice.reducer;