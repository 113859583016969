import React from "react";
import RootLayout from "../../../component/layout";
import Methods from "../../../component/mudrex/methods";

const PaymentMethods = () => {
  return (
    <RootLayout 
    title="Payment Method" onBackClick={() => window.back()}>
      <Methods />
    </RootLayout>
  );
};

export default PaymentMethods;
