import React, { useState, useEffect } from "react";
import { getPaymentStatus } from "../../../redux/actions/mudrexActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import RootLayout from "../../../component/layout";

const MudrexPaymentStatus = () => {
  const [status, setStatus] = useState("awaiting");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const paymentConfig = useSelector((state) => state?.reducer?.checkoutConfig);
  const location = useLocation();
  const paymentIntent = location.state;

  const checkStatus = async () => {
    setLoading(true);
    // Simulate API call
    setTimeout(async () => {
      // Replace this with actual API call when ready
      const response = await dispatch(
        getPaymentStatus(paymentConfig?._id, paymentConfig?.keyUsed)
      );
      setStatus(
        response.data.status == "COMPLETED"
          ? "success"
          : response.data.kycStatus == "FAILED"
            ? "failure"
            : "awaiting"
      );
      if (response.data.status == "COMPLETED") {
        window.location.assign(paymentConfig?.successCallback + `?key=${paymentConfig?.keyUsed}&paymentId=${paymentConfig?._id}`);
      } else if (response.data.status == "FAILED") {
        window.location.assign(paymentConfig?.failureCallback + `?key=${paymentConfig?.keyUsed}&paymentId=${paymentConfig?._id}`);
      } else {
      }
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    paymentIntent?.payment_intent &&
      window.location.assign(paymentIntent?.payment_intent);
  }, []);

  useEffect(() => {
    checkStatus();
    let intervalId;
    if (status === "awaiting") {
      intervalId = setInterval(checkStatus, 10000); // Check every 10 seconds
    }
    return () => clearInterval(intervalId);
  }, [status]);

  const handlePaymentIntent = () => {
    window.location.assign(paymentIntent?.payment_intent);
  };

  const handleButtonClick = () => {
    switch (status) {
      case "awaiting":
        checkStatus();
        break;
    }
  };

  const renderContent = () => {
    switch (status) {
      case "awaiting":
        return (
          <>
            <p className="text-center common-lbl">
              Your payment is under process. It will be verified soon. It can take upto <b>5 minutes</b>.
            </p>
            <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
              <button
                className="bnksy-btn-primary"
                onClick={handleButtonClick}
              >
                {loading ? "Loading..." : "Refresh Status"}
              </button>
              {/* <button
                className="bnksy-btn-primary bnksy-btn-secondary !mt-4"
                onClick={handlePaymentIntent}
              >
                Re-Open App
              </button> */}
            </div>
          </>
        );
    }
  };
  return (
    <RootLayout>
      <div className="w-full h-full flex flex-col items-center justify-center pb-[75px]">
        <h1 className="text-center !mb-0 common-lbl fw-bold font-large error-big-title">
          Payment in progress
        </h1>
        <div className="flex justify-center my-[50px]">
          <img src="/Transaction-Timeout.png" alt="waiting" className="w-[300px]" />
        </div>
        {renderContent()}
      </div>
    </RootLayout>
  );
};

export default MudrexPaymentStatus;
