import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RootLayout from "../../../component/layout";
import { verifyPayment } from "../../../network/api";
import ErrorPage from "../../../component/common/Error";

const StripeFailure = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const searchObject = {};
      const searchParams = new URLSearchParams(location.search);
      for (const [key, value] of searchParams.entries()) {
        searchObject[key] = value;
      }
        if (
          !searchObject.hasOwnProperty("paymentId") ||
          !searchObject.hasOwnProperty("apiKey")
        ) {
          navigate("/error/config");
        } else {
          const status = await verifyPayment(
            searchObject.apiKey,
            searchObject.paymentId
          );
          if (status?.status === "success") {
            window.location.assign(
              status.successCallback + "?paymentId=" + searchObject.paymentId
            );
          } else {
            window.location.assign(
              status.failureCallback + "?paymentId=" + searchObject.paymentId
            );
          }
        }
    };
    fetchData();
  }, [location.search, navigate]);
  return (
    <RootLayout>
      <ErrorPage
      imageSource="/Please-Wait.png"
      title="Please wait."
      description="We are confirming the payment with provider. It will take a while.."
    />
    </RootLayout>
  );
};

export default StripeFailure;
