import { Layout, Row, Col } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import Header from "../common/Header";

const RootLayout = ({ children, title, onBackClick, onInfoClick }) => {
  return (
    <Layout className="w-full h-screen">
      <Content className="w-full main-content-wrapper h-full mx-auto">
        <Row justify="space-around" align="middle" className="w-full h-full">
          <Col xs={24} md={24} className="w-full h-full px-[20px]">
            {title && <Header
              title={title}
              onBackClick={onBackClick}
              onInfoClick={onInfoClick}
            />
            }
            {children}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default RootLayout;
