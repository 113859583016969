import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { verifyToken } from '../utils/utils';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get('customer_access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const apiCall = async (url, method, data, apiKey = null, baseURL) => {
  try {
    const config = {
      url: baseURL || url,
      method,
      data,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (apiKey) {
      config.headers['X-AUTH'] = apiKey;
    }

    const response = await axiosInstance.request(config);
    return response.data;
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
    throw error.response?.data || error;
  }
};