import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMudrexPaymentIntent,
  getMudrexPaymentMethods,
} from "../../redux/actions/mudrexActions";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";
import accounting from "accounting";

const PaymentMethods = () => {
  const checkoutConfig = useSelector((state) => state?.reducer?.checkoutConfig);
  const portalConfig = useSelector((state) => state?.reducer?.portalConfig);
  const [intentPaymentMethods, setIntentPaymentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [theme, setTheme] = useState("dark");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [seconds, setSeconds] = useState(600);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const getPaymentMethods = async () => {
   
    const response = await dispatch(
      getMudrexPaymentMethods(checkoutConfig?.keyUsed)
    );

    if (response.code === 1) {
      // Filter payment methods with mode: "INTENT"
      const intentMethods = response.data?.UPI?.options.filter(
        (method) => method.mode === "INTENT"
      );
      setIntentPaymentMethods(intentMethods);
    }
  };

  useEffect(() => {
    if (!checkoutConfig?.keyUsed) {
      navigate("/error/config");
      return;
    } else {
      getPaymentMethods();
    }
  }, [checkoutConfig?.keyUsed, dispatch, navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(interval);
          navigate("/error/timeout", { state: checkoutConfig });
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handlePaymentMethodClick = async (methodName) => {
    setIsLoading(true);
    const paymentData = {
      paymentId: checkoutConfig?._id,
      paymentMethod: methodName,
    };

    const response = await dispatch(
      getMudrexPaymentIntent(paymentData, checkoutConfig?.keyUsed)
    );
    setIsLoading(false);

    const paymentIntent = response?.data;
    if (response.code === 1) {
      navigate("/awaiting/mudrex", { state: paymentIntent });
    }
  };

  return (
    <form className="w-full flex flex-col items-center justify-start">
      {/* <div className="w-full flex items-center justify-between header-main-row">
        <div className="flex items-center header-left-col">
          <Button
            type="button"
            className="bg-transparent text-lg text-black p-0 me-3"
            onClick={handleCancel}
          >
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.00895386 6.699C0.0761075 6.34085 0.295476 6.08119 0.55066 5.83048C2.36829 4.01733 4.18143 2.20418 5.99458 0.391034C6.37512 0.0104971 6.86758 -0.101426 7.30632 0.0955583C7.99576 0.404465 8.2017 1.27746 7.71819 1.86394C7.65999 1.93557 7.59284 2.00272 7.52569 2.06988C6.6706 2.92944 5.81103 3.78453 4.95146 4.6441C4.55302 5.04254 4.18591 5.48576 3.62182 5.68274C3.6263 5.69617 3.63077 5.7096 3.63077 5.72303C3.68897 5.72303 3.74717 5.72303 3.80537 5.72303C7.44063 5.72303 11.0714 5.72303 14.7067 5.72303C15.0693 5.72303 15.4006 5.79467 15.6602 6.06776C15.9826 6.408 16.09 6.80645 15.9199 7.24518C15.7453 7.70183 15.3916 7.95254 14.9036 8.00626C14.8096 8.01521 14.7156 8.01521 14.6216 8.01521C11.0132 8.01521 7.40929 8.01521 3.8009 8.01521C3.7427 8.01521 3.68002 8.01521 3.63525 8.01521C3.88148 8.18086 4.12771 8.3465 4.36946 8.5211C4.46348 8.58826 4.53959 8.67332 4.62017 8.7539C5.60062 9.73882 6.58106 10.7237 7.56598 11.7042C7.9107 12.0489 8.05844 12.4518 7.92861 12.9264C7.72715 13.6651 6.79595 13.9606 6.18709 13.486C6.10651 13.4233 6.0304 13.3517 5.95429 13.2756C4.15457 11.4759 2.35485 9.67615 0.55066 7.88091C0.299953 7.6302 0.0761075 7.37501 0 7.01686C0.00895382 6.90942 0.00895386 6.80197 0.00895386 6.699Z" fill="black" />
            </svg>
          </Button>
          <h2 className="bnksy-page-title">Summary</h2>
        </div>
      </div> */}
      <div
        className={
          seconds > 10
            ? "timer bg-orange header-right-col"
            : "timer bg-red header-right-col"
        }
      >
        Expires In: {minutes.toString().padStart(2, "0")}:
        {remainingSeconds.toString().padStart(2, "0")}
      </div>
      <div className="flex items-center justify-center my-[20px]">
        <img
          src={
            theme === "light" ? portalConfig?.logoLight : portalConfig?.logoDark
          }
          alt=""
          className="w-8 rounded-full"
        />
      </div>
      <div className="main-top-checkout-section-box flex flex-col items-center mb-[25px]">
        <div className="table-wrapper summary-maintable w-full checkout-summary-table">
          <table className="table w-full">
            <tbody>
              <tr>
                <td className="common-lbl">Date/Time</td>
                <td className="common-lbl text-right">
                  {moment(checkoutConfig.createdAt).format("LLL")}
                </td>
              </tr>
              <tr>
                <td className="common-lbl">
                  <p className="flex items-center justify-start">
                    Amount{" "}
                    <span className="small-text-lbl ml-1">
                      ({checkoutConfig?.currency?.toUpperCase()})
                    </span>
                  </p>
                </td>
                <td className="common-lbl text-right">
                  {accounting.formatMoney(checkoutConfig?.amount, {
                    symbol: getSymbolFromCurrency(
                      checkoutConfig?.currency?.toUpperCase()
                    ),
                    format: "%s%v",
                  })}
                </td>
              </tr>
              <tr>
                <td className="common-lbl">To</td>
                <td className="common-lbl text-right">{portalConfig?.to}</td>
              </tr>
              {portalConfig?.description && (
                <tr>
                  <td className="common-lbl border-hidden" colSpan="2">
                    <p className="small-text-lbl !text-left mt-0">
                      {" "}
                      {portalConfig?.description}
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {checkoutConfig?.currency?.toUpperCase() === "INRD" ? (
        <p className="text-white text-left font-regular fw-normal bg-red p-3 rounded-md">
          <b>⚠️ Attention:</b> To proceed with this transaction, please ensure
          that <b>international payments</b> are enabled on your card.
        </p>
      ) : null}
      <div className="w-full mb-[110px]">
        <div className="w-full flex flex-col items-center justify-start">
          <h3 className="w-full common-lbl mb-[15px]">
            Select Payment Methods
          </h3>
          {intentPaymentMethods.length > 0 ? (
            <ul className="w-full">
              {intentPaymentMethods.map((method) => (
                <li
                  key={method.name}
                  onClick={() => handlePaymentMethodClick(method.name)}
                  className={`payment-method-box w-full cursor-pointer bg-line-gray p-4 rounded-xl common-lbl mb-[10px] flex justify-between items-center ${
                    isLoading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {method.display_name}
                  <img
                    src="/external-link-icon.svg"
                    alt="icon"
                    className="logo-transparent-white"
                  />
                </li>
              ))}
            </ul>
          ) : (
            <div className="flex justify-center items-center w-full text-xs text-gray-400 text-center">
              <div className="w-5 h-5 border-2 border-white border-t-transparent border-solid rounded-full animate-spin me-4"></div>
              <span>Loading...</span>
            </div>
          )}
        </div>
      </div>
      {/* <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
      <Button
        type="primary"
        htmlType="submit"
        className={`bnksy-btn-primary`}
        disabled={!stripe || !elements}
        loading={loading}
        style={{ backgroundColor: `${theme === "light" ? portalConfig?.light : portalConfig?.dark}` }}
      >
        Pay
        {accounting.formatMoney(payment?.amount, {
          symbol: getSymbolFromCurrency(payment?.currency?.toUpperCase()),
          format: " %s%v",
        })}
      </Button>
    </div> */}
    </form>
  );
};

export default PaymentMethods;
