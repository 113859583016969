import { useState } from "react";
import maleIcon from "../../images/male-symbol.svg";
import femaleIcon from "../../images/female-symbol.svg";
import otherGenderIcon from "../../images/other-gender-symbol.png";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  createSession,
  updatePayment,
} from "../../redux/actions/customerActions";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk";
import Button from "../common/Button";

const userDetails = {
  firstName: "",
  lastName: "",
  gender: "",
  dateOfBirth: "",
};

const KycForm = () => {
  const [user, setUser] = useState(userDetails);
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state?.reducer?.customerData);
  const checkoutConfig = useSelector((state) => state?.reducer?.checkoutConfig);
  const [loadingverify, setLoadingVerify] = useState(false);

  const getDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleUserDetails = (fieldName, value) => {
    setUser({
      ...user,
      [fieldName]: value,
    });
  };

  const keyUsed = useSelector(
    (state) => state?.reducer?.checkoutConfig?.keyUsed
  );

  const handleSubmit = async () => {
    if (!validateFields()) {
      return;
    }
    const { firstName, lastName, gender, dateOfBirth } = user;
    const dob = getDate(dateOfBirth);

    setLoadingVerify(true);

    const response = await dispatch(
      createSession(
        {
          firstName,
          lastName,
          gender,
          customerID: customerData?.customerID || "",
          dateOfBirth: dob,
        },
        keyUsed
      )
    );
    setLoadingVerify(false);

    if (response.code === 1) {
      if (response.data.url) {
        // Redirect to Veriff's hosted UI
        //   window.location.href = veriffSession.verification.url;

        // Alternatively, for in-context verification:
        //   import { createVeriffFrame } from '@veriff/js-sdk';
        createVeriffFrame({
          url: response.data.url,
          onEvent: async function (msg) {
            switch (msg) {
              case MESSAGES.CANCELED:
                let data = {
                  kycStatus: "cancelled",
                };
                let response = await dispatch(
                  updatePayment(data, checkoutConfig?._id)
                );
                if (response.code === 1) {
                  window.location.assign(
                    checkoutConfig?.failureCallback +
                    "?paymentId=" +
                    checkoutConfig?._id
                  );
                  toast.error("KYC Canceled!");
                } else {
                  toast.error("KYC Canceled!");
                }

                break;
              case MESSAGES.FINISHED:
                navigate("/auth/kyc/status");
                toast.success("KYC Submitted!");
                break;
              default:
                break;
            }
          },
        });
      }
    } else {
      showErrorMessage(response.data);
    }
  };

  const validateName = (name) => {
    const regex = /^(?!\s*$).+/;
    return regex.test(name);
  };

  const validateFields = () => {
    const errors = {};
    if (user.firstName === "") {
      errors.firstName = "Please enter your first name";
    } else if (!validateName(user.firstName)) {
      errors.firstName = "First name cannot be only spaces";
    }
    if (user.lastName === "") {
      errors.lastName = "Please enter your last name";
    } else if (!validateName(user.lastName)) {
      errors.lastName = "Last name cannot be only spaces";
    }
    if (user.gender === "") {
      errors.gender = "Please select your gender";
    }
    if (user.dateOfBirth === "" || user.dateOfBirth === null) {
      errors.dateOfBirth = "Please Select Date of Birth";
    }

    // if (user.email === "") {
    //   errors.email = "Please enter your email";
    // } else if (!validateEmail(user.email)) {
    //   errors.email = "Please enter a valid email address";
    // }
    // if (user.contactNumber === "") {
    //   errors.contactNumber = "Please enter your contact number";
    // } else if (!validateNumber(user.contactNumber)) {
    //   errors.contactNumber = "Please enter a valid 10-digit contact number";
    // }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const showErrorMessage = (errMessage) =>
    toast.error(errMessage, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      theme: "colored",
      transition: Bounce,
    });

  return (
    <div className="w-full flex flex-col items-center justify-start">
      <div className="w-full bnksy-input-group mb-[20px]">
        <input
          type="text"
          className="bnksy-form-input"
          id="signup-name"
          value={user.firstName}
          placeholder="First Name"
          onChange={(e) => handleUserDetails("firstName", e.target.value)}
          required
        />
        {validationErrors.firstName && (
          <p className="text-red-600 font-small">
            {validationErrors.firstName}
          </p>
        )}
      </div>
      <div className="w-full bnksy-input-group mb-[20px]">
        <input
          type="text"
          className="bnksy-form-input"
          id="signup-name"
          value={user.lastName}
          placeholder="Last Name"
          onChange={(e) => handleUserDetails("lastName", e.target.value)}
          required
        />
        {validationErrors.lastName && (
          <p className="text-red-600 font-small">
            {validationErrors.lastName}
          </p>
        )}
      </div>
      <div className="w-full bnksy-input-group mb-[20px]">
        <DatePicker
          id="date-of-birth"
          className="bnksy-form-input"
          placeholderText="Date of Birth"
          selected={user.dateOfBirth}
          showYearDropdown
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 48 48"
            >
              <mask id="ipSApplication0">
                <g
                  fill="none"
                  stroke="#fff"
                  strokeLinejoin="round"
                  strokeWidth="4"
                >
                  <path
                    strokeLinecap="round"
                    d="M40.04 22v20h-32V22"
                  ></path>
                  <path
                    fill="#fff"
                    d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                  ></path>
                </g>
              </mask>
              <path
                fill="currentColor"
                d="M0 0h48v48H0z"
                mask="url(#ipSApplication0)"
              ></path>
            </svg>
          }
          onChange={(date) => handleUserDetails("dateOfBirth", date)}
        />
        <div className="absolute calendar-icon-box right-[16px] z-0">
          <img
            src="/calendar-icon.svg"
            alt="logo"
            className="w-full logo-transparent-white"
          />
        </div>
        {validationErrors.dateOfBirth && (
          <p className="text-red-600 font-small">
            {validationErrors.dateOfBirth}
          </p>
        )}
      </div>
      <div className="w-full bnksy-input-group gender-input-group">
        <div className="flex items-center gender-title relative">
          <span className="common-lbl text-center w-full">
            Select Gender
          </span>
        </div>
        <div className="flex gap-3 flex-row items-center w-full">
          <div
            className={`inline-block cursor-pointer gender-box transition-colors w-1/3 text-center ${user.gender === "male" ? "active" : ""
              }`}
            onClick={() => handleUserDetails("gender", "male")}
          >
            <div className="gender-icon-box">
              <img
                className={`${user.gender === "male" ? "" : "opacity-50"
                  } h-8 mx-auto logo-transparent-white`}
                src={maleIcon}
                alt="Male"
              />
            </div>
            <h6 className="gender-lbl">Male</h6>
          </div>
          <div
            className={`inline-block cursor-pointer gender-box transition-colors w-1/3 text-center ${user.gender === "female" ? "active" : ""
              }`}
            onClick={() => handleUserDetails("gender", "female")}
          >
            <div className="gender-icon-box">
              <img
                className={`${user.gender === "female" ? "" : "opacity-50"
                  } h-8 mx-auto logo-transparent-white`}
                src={femaleIcon}
                alt="Female"
              />
            </div>
            <h6 className="gender-lbl">Female</h6>
          </div>
          <div
            className={`inline-block cursor-pointer gender-box transition-colors w-1/3 text-center ${user.gender === "other" ? "active" : ""
              }`}
            onClick={() => handleUserDetails("gender", "other")}
          >
            <div className="gender-icon-box">
              <img
                className={`${user.gender === "other" ? "" : "opacity-50"
                  } h-8 mx-auto logo-transparent-white`}
                src={otherGenderIcon}
                alt="Other"
              />
            </div>
            <h6 className="gender-lbl">Others</h6>
          </div>
        </div>
        {validationErrors.gender && (
          <p className="text-red-600 font-small">
            {validationErrors.gender}
          </p>
        )}
      </div>
      <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
        <Button
          onClick={handleSubmit}
          loading={loadingverify}
          disabled={
            !user.firstName ||
            !user.lastName ||
            !user.gender ||
            !user.dateOfBirth
          }
        >
          {" "}
          Next
        </Button>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default KycForm;
