import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RootLayout from "../../../component/layout";
import { getSumupPayment } from "../../../network/api";
import Awaiting from "../../../component/common/Awaiting";

const SumupSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let { apikey } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const searchObject = {};
      const searchParams = new URLSearchParams(location.search);
      for (const [key, value] of searchParams.entries()) {
        searchObject[key] = value;
      }
      const status = await getSumupPayment(apikey, searchObject?.checkout_id);
      if (status?.status === "success") {
        window.location.assign(
          status.successCallback + "?paymentId=" + status.id
        );
      } else if (status?.status === "failed") {
        window.location.assign(
          status.failureCallback + "?paymentId=" + status.id
        );
      } else {
        fetchData();
      }
    };
    fetchData();
  }, [location.search, navigate]);
  return (
    <RootLayout>
      <Awaiting />
    </RootLayout>
  );
};

export default SumupSuccess;
