import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

const Header = ({
  onBackClick,
  title,
  onInfoClick,
  className = ""
}) => {
  return (
    <header className={`w-full pt-5 pb-7 ${className}`}>
      <Row justify="space-beteen" align="middle" className="w-full">
        <Col xs={2} md={2} className="flex">
          {onBackClick && (
            <button
              onClick={onBackClick}
              className="text-lbl-para"
              aria-label="Go back"
            >
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.00895386 6.699C0.0761075 6.34085 0.295476 6.08119 0.55066 5.83048C2.36829 4.01733 4.18143 2.20418 5.99458 0.391034C6.37512 0.0104971 6.86758 -0.101426 7.30632 0.0955583C7.99576 0.404465 8.2017 1.27746 7.71819 1.86394C7.65999 1.93557 7.59284 2.00272 7.52569 2.06988C6.6706 2.92944 5.81103 3.78453 4.95146 4.6441C4.55302 5.04254 4.18591 5.48576 3.62182 5.68274C3.6263 5.69617 3.63077 5.7096 3.63077 5.72303C3.68897 5.72303 3.74717 5.72303 3.80537 5.72303C7.44063 5.72303 11.0714 5.72303 14.7067 5.72303C15.0693 5.72303 15.4006 5.79467 15.6602 6.06776C15.9826 6.408 16.09 6.80645 15.9199 7.24518C15.7453 7.70183 15.3916 7.95254 14.9036 8.00626C14.8096 8.01521 14.7156 8.01521 14.6216 8.01521C11.0132 8.01521 7.40929 8.01521 3.8009 8.01521C3.7427 8.01521 3.68002 8.01521 3.63525 8.01521C3.88148 8.18086 4.12771 8.3465 4.36946 8.5211C4.46348 8.58826 4.53959 8.67332 4.62017 8.7539C5.60062 9.73882 6.58106 10.7237 7.56598 11.7042C7.9107 12.0489 8.05844 12.4518 7.92861 12.9264C7.72715 13.6651 6.79595 13.9606 6.18709 13.486C6.10651 13.4233 6.0304 13.3517 5.95429 13.2756C4.15457 11.4759 2.35485 9.67615 0.55066 7.88091C0.299953 7.6302 0.0761075 7.37501 0 7.01686C0.00895382 6.90942 0.00895386 6.80197 0.00895386 6.699Z" fill="black" className="svg-fill-area" />
              </svg>
            </button>
          )}
        </Col>
        <Col xs={20} md={20} className="flex justify-center">
          <h1 className="bnksy-page-title">{title}</h1>
        </Col>
        <Col xs={2} md={2} className="flex justify-end">
          {onInfoClick && (
            <button
              onClick={onInfoClick}
              className="text-lbl-para"
              aria-label="Information"
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.18158 0C7.50158 0 7.82158 0 8.14158 0C8.40158 0.036 8.66558 0.06 8.92558 0.104C10.3696 0.352 11.6496 0.956 12.7416 1.932C14.0696 3.116 14.8976 4.588 15.2136 6.344C15.2616 6.624 15.2936 6.904 15.3296 7.184C15.3296 7.504 15.3296 7.824 15.3296 8.144C15.3056 8.328 15.2896 8.508 15.2616 8.692C15.0256 10.352 14.3416 11.808 13.1576 12.996C11.1896 14.964 8.81358 15.7 6.08158 15.16C4.22958 14.796 2.72558 13.816 1.58158 12.316C0.297579 10.636 -0.202421 8.728 0.0735788 6.632C0.293579 4.952 1.01758 3.504 2.20558 2.296C3.34958 1.132 4.72958 0.4 6.34158 0.116C6.61758 0.068 6.90158 0.04 7.18158 0ZM7.65358 13.412C10.8216 13.42 13.4056 10.836 13.4096 7.668C13.4136 4.504 10.8416 1.928 7.67758 1.916C4.50958 1.904 1.92158 4.48 1.91358 7.652C1.90158 10.82 4.48158 13.408 7.65358 13.412Z" fill="black" className="svg-fill-area" />
                <path d="M8.61754 8.83201C8.61754 9.48001 8.62154 10.128 8.61754 10.776C8.61354 11.232 8.28154 11.604 7.82154 11.692C7.39754 11.772 6.94554 11.528 6.78154 11.116C6.73354 11 6.70554 10.864 6.70554 10.74C6.70154 9.46401 6.70154 8.18801 6.70154 6.91201C6.70154 6.37201 7.12954 5.95201 7.66154 5.95601C8.19354 5.95601 8.61354 6.37601 8.61354 6.92001C8.62154 7.55601 8.61754 8.19601 8.61754 8.83201Z" fill="black" className="svg-fill-area" />
                <path d="M6.70154 4.58C6.70154 4.048 7.12954 3.62 7.66154 3.62C8.19354 3.62 8.62154 4.048 8.61754 4.584C8.61754 5.108 8.18954 5.53599 7.66554 5.53599C7.13354 5.53999 6.70154 5.112 6.70154 4.58Z" fill="black" className="svg-fill-area" />
              </svg>
            </button>
          )}
        </Col>
      </Row>
    </header>
  );
};

Header.propTypes = {
  onBackClick: PropTypes.func,
  backIcon: PropTypes.node,
  title: PropTypes.string.isRequired,
  onInfoClick: PropTypes.func,
  className: PropTypes.string
};

export default Header;