import React, { useState, useEffect } from "react";
import QRCode from "qrcode";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../common/Button";
import accounting from "accounting";

const ReceiveCentaurus = () => {
  const paymentObj = useSelector((state) => state?.reducer?.checkoutConfig);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [upiOptions, setUpiOptions] = useState([]);
  const [seconds, setSeconds] = useState(600);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success("Copied to clipboard!");
      },
      () => {
        toast.error("Could not copy text.");
      }
    );
  };

  const getPinwheelKeyId = async () => {
    setUpiOptions(paymentObj?.meta?.upiConfig);
    QRCode.toDataURL(
      paymentObj?.meta?.upiUri,
      {
        width: 160,
        margin: 0,
        color: {
          dark: "#000000",
          light: "#ffffff",
        },
      },
      (err, url) => {
        if (err) {
          console.error("Error generating QR code:", err);
        } else {
          setQrCodeUrl(url);
        }
      }
    );
  };

  const handleUPIClick = (uri) => {
    window.location.href = uri;
  };

  useEffect(() => {
    if (paymentObj) {
      getPinwheelKeyId();
    }
  }, [paymentObj]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(interval);
          navigate("/error/timeout");
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [navigate]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return (
    <div className="w-full flex flex-col items-center justify-start">
      <div className="mb-3 w-full">
        <div
          className={
            seconds > 10
              ? "timer bg-orange header-right-col"
              : "timer bg-red header-right-col"
          }
        >
          Expires In: {minutes.toString().padStart(2, "0")}:
          {remainingSeconds.toString().padStart(2, "0")}
        </div>
      </div>
      <div className="main-top-section-box w-full flex flex-col items-center relative">
        <h2 className="text-xl font-bold text-center">
          Pay{" "}
          <span className="text-[--primary-color]">
            {paymentObj?.currency}{" "}
            {accounting.formatNumber(paymentObj?.amount, { precision: 2 })}
          </span>
        </h2>
        <p className="text-gray-600 text-center mb-4">
          Pay by UPI the fast, simple and secure way to pay
        </p>

        <div className="mt-5 flex flex-col items-center justify-center">
          {qrCodeUrl && (
            <img src={qrCodeUrl} alt="QR Code" className="w-40 h-40" />
          )}
          <div className=" p-2 mt-2 rounded-lg flex flex-row items-center justify-center">
            <span className="text-gray-800 text-[14px] bg-gray-200 p-2 rounded">
              CODE : {paymentObj?.meta?.note}
            </span>
            <button
              onClick={() => copyToClipboard(paymentObj?.meta?.note)}
              className="ml-2 p-2 bg-gray-300 rounded-lg"
            >
              <img src="/copy-icon.svg" alt="copy" className="w-4 h-4" />
            </button>
          </div>

          
          <p className="text-gray-500 text-center mt-4">
            OR PAY USING YOUR UPI APP
          </p>
          <div className="flex items-center justify-center mt-2 space-x-4 border-2 border-gray-200 p-2 rounded-lg">
            {upiOptions.map((option, index) => (
              <button
                key={index}
                onClick={() => handleUPIClick(option.uri)}
                className="flex flex-col items-center focus:outline-none"
                title={option.title}
              >
                <img
                  src={option.logo}
                  alt={option.title}
                  className="w-6 h-6 object-contain"
                />
                <span className="text-xs text-center mt-1">{option.title}</span>
              </button>
            ))}
          </div>
          <p className="text-blue mt-4 cursor-pointer">
            To receive a payment confirmation <br />
            <Button
              onClick={() => navigate("/awaiting/centaurus")}
              className="!mt-5"
            >
              Click Here
            </Button>
          </p>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default ReceiveCentaurus;
