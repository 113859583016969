import { CUSTOMER_AUTH_FAILURE, CUSTOMER_AUTH_SUCCESS, PORTAL_CONFIG_SUCCESS } from "../actionTypes";

export default function reducer(state = {}, action) {  let { type, payload } = action;

  switch (type) {

    case CUSTOMER_AUTH_FAILURE:
      // Assuming 'payload' contains user information you want to store in the state
      return { ...state, error: null };

    case CUSTOMER_AUTH_SUCCESS:
      // Assuming 'payload' contains error information you want to store in the state
      return { ...state, customerData: payload };

    case PORTAL_CONFIG_SUCCESS:
      // Assuming 'payload' contains error information you want to store in the state
      return { ...state, portalConfig: payload };

    case "SET_CHECKOUT_CONFIG":
      return { ...state, checkoutConfig: payload };

    default:
      return state;
  }
}