import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

const Element = ({ stripeKey, clientSecret, apiKey, paymentId, payment, theme }) => {
  const stripePromise = loadStripe(stripeKey);
  const appearance = {
    theme,
  };
  const options = {
    clientSecret,
    appearance,
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm apiKey={apiKey} paymentId={paymentId} payment={payment} />
    </Elements>
  );
};

export default Element;
