import React, { useEffect, useState } from "react";
import RootLayout from "../../component/layout";
import ReceiveCrypto from "../../component/crypto/receive";
import ConfirmationCrypto from "../../component/crypto/confirmationCrypto";
import useSocket from "../../redux/hooks/socketHook";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "../../component/common/Modal";

const Crypto = () => {
  useSocket();
  // const [theme, setTheme] = useState("dark");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const keyUsed = useSelector(
    (state) => state?.reducer?.checkoutConfig?.keyUsed
  );

  const paymentId = useSelector(
    (state) => state?.reducer?.checkoutConfig?._id
  );

  const failureCallbackURL = useSelector(
    (state) => state?.reducer?.checkoutConfig?.failureCallback
  );
  const handleBackClick = () => {
    window.location.assign(failureCallbackURL + `?key=${keyUsed}&paymentId=${paymentId}`);
  };
  // const customerId = useSelector((state) => state?.reducer?.checkoutConfig?.customerId?._id) || useSelector((state) => state?.reducer?.customerData?.customerID);

  useEffect(() => {
    if (!keyUsed) {
      navigate("/error/config");
      return;
    }
    // Set attributes on the body tag
    // document.body.setAttribute("data-bs-theme", theme);

    // // Clean up function to remove attributes when the component unmounts
    // return () => {
    //   document.body.setAttribute("data-bs-theme", theme);
    // };
  }, [keyUsed, navigate]);

  return (
    <RootLayout
      title="Receive"
      onBackClick={handleBackClick}
      onInfoClick={() => setShowModal(true)} // Set modal to show on info click
    >
      <div className="w-full pb-[110px]">
        <ReceiveCrypto />
        <ConfirmationCrypto />
      </div>

      {/* Modal Component */}
      <Modal
        title="Payment Instructions & FAQs"
        isVisible={showModal}
        onClose={() => setShowModal(false)}
      />
    </RootLayout>
  );
};

export default Crypto;
