

export const setCheckoutConfig = (payload) => ({
    type: "SET_CHECKOUT_CONFIG",
    payload,
})

export const setCustomerData = (payload) => ({
    type: "CUSTOMER_AUTH_SUCCESS",
    payload,
})