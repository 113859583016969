import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import QRCode from "qrcode";
import { toast, ToastContainer } from "react-toastify";
import { WhatsappShareButton } from "react-share";
import accounting from "accounting";
import { useNavigate } from "react-router-dom";

const ReceiveCrypto = () => {
  const paymentObj = useSelector((state) => state?.reducer?.checkoutConfig);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [seconds, setSeconds] = useState(600);
  const navigate = useNavigate();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success("Copied to clipboard!");
      },
      () => {
        toast.error("Could not copy text: ");
      }
    );
  };

  useEffect(() => {
    if (paymentObj?.evmAddress) {
      const qrContent = `${paymentObj.evmAddress}`;
      QRCode.toDataURL(
        qrContent,
        {
          width: 160,
          margin: 0,
          color: {
            dark: "#000000",
            light: "#ffffff",
          },
        },
        (err, url) => {
          if (err) {
            console.error("Error generating QR code:", err);
          } else {
            setQrCodeUrl(url);
          }
        }
      );
    }
  }, [paymentObj?.evmAddress]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(interval);
          navigate("/error/timeout", { state: paymentObj });
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [paymentObj, navigate]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return (
    <div className="w-full flex flex-col items-center justify-start">
      <div className="mb-3 w-full">
        <div
          className={
            seconds > 10
              ? "timer bg-orange header-right-col"
              : "timer bg-red header-right-col"
          }
        >
          Expires In: {minutes.toString().padStart(2, "0")}:
          {remainingSeconds.toString().padStart(2, "0")}
        </div>
      </div>
      <div className="w-full note-box p-3 mb-4">
        <p className="text-white common-lbl">
          Only send{" "}
          <span className="text-white fw-bold">
            {paymentObj?.crypto?.tokenName} (
            {paymentObj?.crypto?.blockchainSymbol})
          </span>{" "}
          assets to this address. Other assets will be lost forever.
        </p>
      </div>
      <div className="main-top-section-box w-full flex flex-col items-center relative">
        <img
          src="/Banksy-icon.png"
          alt="banksy"
          className="center-fixed-logo-icon"
        />
        <div className="flex items-center justify-center">
          <img
            src={paymentObj?.crypto?.logo}
            alt="crypto icon"
            className="w-7 h-7"
          />
          <span className="common-lbl fw-bold font-xl text-center text-black mx-2 big-font-lbl">
            {paymentObj?.crypto?.tokenName}
          </span>
          <span className="coin-badge">TOKEN</span>
        </div>
        <div className="mt-5 flex flex-col items-center justify-center">
          {qrCodeUrl && (
            <img src={qrCodeUrl} alt="QR Code" className="w-40 h-40" />
          )}
          <div className="common-lbl text-black font-small text-center break-all mt-3 trx-address">
            {paymentObj?.evmAddress}
          </div>
        </div>
        <div className="flex justify-center items-center mt-2 mb-4">
          <div className="common-lbl text-black fw-bold text-center">
            {accounting
              .formatMoney(paymentObj?.crypto?.amount, {
                precision: 6,
                symbol: "",
              })
              .replace(/\.?0+$/, "")}{" "}
            {paymentObj?.crypto?.tokenName} ≈{" "}
            <span className="text-green">
              {" "}
              {accounting
                .formatMoney(
                  paymentObj?.crypto?.amount *
                    paymentObj?.crypto?.conversionRate,
                  { precision: 6, symbol: "" }
                )
                .replace(/\.?0+$/, "")}{" "}
              USD
            </span>
          </div>
        </div>
        <div className="flex items-center justify-center mb-4">
          <button
            className="flex flex-col items-center"
            onClick={() => copyToClipboard(paymentObj?.evmAddress)}
          >
            <span className="bg-gray w-10 h-10 p-2 rounded-xl flex justify-center items-center">
              <img src="/copy-icon.svg" alt="copy" />
            </span>
          </button>
          <button className="flex flex-col items-center ml-6">
            <span className="bg-gray w-10 h-10 p-2 rounded-xl flex justify-center items-center">
              <WhatsappShareButton
                url={window.location.href + "/" + paymentObj?.evmAddress}
              >
                <img src="/share-icon.svg" alt="share" />
              </WhatsappShareButton>
            </span>
          </button>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default ReceiveCrypto;
