import React, { useState, useEffect } from "react";
import { faqContent } from "../../utils/utils";

export default function Modal({ title, isVisible, onClose }) {
  const [isClosing, setIsClosing] = useState(false);

  // Effect to reset closing state when modal reopens
  useEffect(() => {
    if (isVisible) {
      setIsClosing(false);
    }
  }, [isVisible]);

  // Close handler to trigger animation
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose(); // Only after animation ends
    }, 300); // Duration of the close animation
  };

  // Return null if modal is not visible and not closing
  if (!isVisible) return null;

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm transition-opacity duration-300 ease-in-out">
        <div
          className={`relative w-full max-w-xl mx-4 sm:mx-auto my-6 border border-gray-700 rounded-lg shadow-lg bg-[#121212] text-gray-300 transition-transform transform-gpu duration-300 ease-in-out ${
            isClosing ? "animate-modal-close" : "animate-modal-pop"
          }`}
        >
          <div className="flex items-start justify-between p-5 border-b border-gray-700 rounded-t">
            <h3 className="text-2xl font-semibold text-white">{title}</h3>
            <button
              className="text-gray-400 hover:text-red-500 transition-colors duration-150"
              onClick={handleClose}
            >
              <span className="text-2xl">&times;</span>
            </button>
          </div>
          {faqContent}
          <div className="flex items-center justify-end p-5 border-t border-gray-700 rounded-b">
            <button
              className="px-6 py-2 bg-red-500 hover:bg-red-600 text-white font-semibold rounded-lg shadow-md transition-all duration-150 ease-in-out"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
