import React from "react";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";

const ErrorPage = ({
  imageSource,
  title,
  description,
  actionLink,
  actionText
}) => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <Title level={1} className="text-center !mb-0 common-lbl text-lbl-para fw-bold font-large error-big-title">
        {title}
      </Title>
      <div className="flex justify-center my-[50px]">
        <img src={imageSource} alt="error" className="w-[300px]" />
      </div>
      <Paragraph className="text-center common-lbl !mb-0">
        {description}
        {actionLink && actionText && (
          <>
            {" "}
            <a className="text-underline" href={actionLink}>
              {actionText}
            </a>
          </>
        )}
      </Paragraph>
    </div>
  );
};

export default ErrorPage;