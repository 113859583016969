import React from "react";
import RootLayout from "../../../component/layout";
import ErrorPage from "../../../component/common/Error";

const ConfigError = () => {
  return (
    <RootLayout>
      <ErrorPage
        imageSource="/Error-Invalid-Config.png"
        title="Oops! Invalid configuration."
        description="Please check the configuration or contact the site admin."
      />
    </RootLayout>
  );
};

export default ConfigError;
