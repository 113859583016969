import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ 
  onClick, 
  children, 
  disabled, 
  className, 
  type = 'button',
  loading = false,
  loadingText = 'Loading...',
  loadingSpinnerClassName = 'ti-spinner text-white'
}) => {
  const baseClassName = 'bnksy-btn-primary w-full';
  const combinedClassName = `${baseClassName} ${className || ''}`.trim();

  return (
    <button
      type={type}
      className={combinedClassName}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <>
          <span
            className={loadingSpinnerClassName}
            role="status"
            aria-label="loading"
          ></span>
          <span>{loadingText}</span>
        </>
      ) : children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  loadingSpinnerClassName: PropTypes.string
};

export default Button;