import React, { useState, useEffect } from "react";
import RootLayout from "../../../component/layout";
import { toast, ToastContainer } from "react-toastify";
import Awaiting from "../../../component/common/Awaiting";
import { useDispatch, useSelector } from "react-redux";
import { getPayment } from "../../../network/api";
import { pinwheelPaymentConfirmation } from "../../../redux/actions/pinwheelActions";

const CentaurusPaymentStatus = () => {
  const paymentObj = useSelector((state) => state?.reducer?.checkoutConfig);

  const [confirmationCode, setConfirmationCode] = useState("");
  const [isCodeEntered, setIsCodeEntered] = useState(false);
  const [status, setStatus] = useState("awaiting");
  const dispatch = useDispatch();


  const handleCodeSubmit = async () => {
    if (!confirmationCode.trim()) {
      toast.error("Confirmation code is required.");
      return;
    }
   const response =  await dispatch(
      pinwheelPaymentConfirmation(
        paymentObj?._id,
        paymentObj?.keyUsed,
        confirmationCode
      )
    );
    if(response.code == 1){
      setIsCodeEntered(true);
    }else{
      toast.error(response.data);
    }
  };


  const checkStatus = async () => {
    const response = await getPayment(paymentObj?.keyUsed, paymentObj?._id);
    setStatus(response.status);
    if (response.status == "success") {
      window.location.assign(`${paymentObj?.successCallback}?key=${paymentObj?.keyUsed}&paymentId=${paymentObj?._id}`);
    } else if (response.status == "failed") {
      window.location.assign(`${paymentObj?.failureCallback}?key=${paymentObj?.keyUsed}&paymentId=${paymentObj?._id}`);
    } else {
    }
  };

 

  useEffect(() => {
    if(isCodeEntered) {
    checkStatus()
    let intervalId;
    if (status === "pending" || status === "awaiting") {
      intervalId = setInterval(checkStatus, 5000); // Check every 10 seconds
    }
      return () => clearInterval(intervalId);
    }
  }, [status, isCodeEntered]);

  return (
    <RootLayout>
      <div className="w-full h-full flex flex-col items-center justify-center pb-[75px]">
      {!isCodeEntered ? (
          <div className="flex flex-col items-center w-full ">
            <h1 className="text-center common-lbl fw-bold font-large mb-5 ">
              Enter Payment UTR
            </h1>

            <div className="relative w-full">
              <input
                type="text"
                className="bnksy-form-input pr-[50px]"
                id="confirmation-code"
                placeholder="Enter Payment UTR"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                required
              />
              <button
                className="absolute right-[15px] top-1/2 -translate-y-1/2 p-2"
                onClick={async () => {
                  try {
                    const text = await navigator.clipboard.readText();
                    setConfirmationCode(text);
                  } catch (err) {
                    toast.error("Failed to paste. Please check clipboard permissions.");
                  }
                }}
              >
                <img 
                  src="/paste-icon.svg" 
                  alt="paste"
                  className="w-5 h-5"
                />
              </button>
            </div>
            <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
              <button
                className="bnksy-btn-primary !mt-5"
                onClick={handleCodeSubmit}
              >
                Submit Code
              </button>
            </div>
          </div>
        ) : ( <Awaiting
          imageSource="/Please-Wait.png"
          title="Payment in progress"
          description="Your payment is under process. It will be verified soon. It can take up to 5 minutes."
          buttonText="Refresh Status"
            onButtonClick={checkStatus}
          />
        )}
      </div>
      <ToastContainer position="bottom-right" />
    </RootLayout>
  );
};

export default CentaurusPaymentStatus;
