import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RootLayout from "../../../component/layout";
import { verifyTripleaPayment } from "../../../network/api";
import Awaiting from "../../../component/common/Awaiting";

const TripleAawaiting = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const { payment_reference, apikey } = location.state;

      if (!payment_reference || !apikey) {
        navigate("/error/config");
      } else {
        const status = await verifyTripleaPayment(apikey, payment_reference);
        if (status?.status === "success") {
          window.location.assign(
            status.successCallback + "?paymentId=" + status._id
          );
        } else if (status?.status === "failure") {
          window.location.assign(
            status.failureCallback + "?paymentId=" + status._id
          );
        } else {
        }
      }
    };
    fetchData();

    const intervalId = setInterval(fetchData, 120000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [location.search, navigate]);

  const successFallback = async () => {
    const { payment_reference, apikey } = location.state;

    if (!payment_reference || !apikey) {
      navigate("/error/config");
    } else {
      const status = await verifyTripleaPayment(apikey, payment_reference);
      window.location.assign(
        status.successCallback + "?paymentId=" + status._id
      );
    }
  };

  return (
    <RootLayout>
      <Awaiting
        buttonText={"Go back"}
        onButtonClick={successFallback}
        title="Please wait..."
        description="It will take time to process your payment"
        imageSource="/Please-Wait.png"
      />
    </RootLayout>
  );
};

export default TripleAawaiting;
