import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RootLayout from "../../../component/layout";
import { verifyWyrapayPayment } from "../../../network/api";
import Awaiting from "../../../component/common/Awaiting"

const WyrapaySuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const searchObject = {};
      const searchParams = new URLSearchParams(location.search);
      for (const [key, value] of searchParams.entries()) {
        searchObject[key] = value;
      }
      if (
        !searchObject.hasOwnProperty("transaction_uuid") ||
        !searchObject.hasOwnProperty("external_client_id")
      ) {
        navigate("/error/config");
      } else {
        const status = await verifyWyrapayPayment(
          searchObject.external_client_id,
          searchObject.transaction_uuid
        );
        if (status?.status === "success") {
          window.location.assign(
            status.successCallback + "?paymentId=" + status._id
          );
        } else {
          window.location.assign(
            status.failureCallback + "?paymentId=" + status._id
          );
        }
      }
    };
    fetchData();
  }, [location.search, navigate]);
  return (
    <RootLayout>
      <Awaiting />
    </RootLayout>
  );
};

export default WyrapaySuccess;
