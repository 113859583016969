import React from "react";
import RootLayout from "../../component/layout";
import KycForm from "../../component/kyc/KycForm";

const RegisterKyc = () => {
  return (
    <RootLayout
      title="KYC Verification">
      <KycForm />
    </RootLayout>
  );
};

export default RegisterKyc;
