import React from "react";
import RootLayout from "../../component/layout";
import StatusModal from "../../component/kyc/status"

const KycStatus = () => {
  return (
    <RootLayout>
      <StatusModal />
    </RootLayout>
  );
};

export default KycStatus;
