import React, { useEffect, useState } from "react";
import RootLayout from "../../component/layout";
import CheckoutSombrero from "../../component/sombrero/CheckoutForm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getModulePublicKey } from "../../network/api";
const Sombrero = () => {
  const navigate = useNavigate();
  const [keys, setKeys] = useState(null);

  const keyUsed = useSelector(
    (state) => state?.reducer?.checkoutConfig?.keyUsed
  );
  const paymentId = useSelector(
    (state) => state?.reducer?.checkoutConfig?._id
  );

  const failureCallbackURL = useSelector(
    (state) => state?.reducer?.checkoutConfig?.failureCallback
  );
  const handleBackClick = () => {
    window.location.assign(failureCallbackURL + `?key=${keyUsed}&paymentId=${paymentId}`);
  };
  // const customerId = useSelector((state) => state?.reducer?.checkoutConfig?.customerId?._id) || useSelector((state) => state?.reducer?.customerData?.customerID);

  const getSombreroKeys = async () => {


    const resKey = await getModulePublicKey(keyUsed, "sombrero");
    if (!resKey?.key) {
      navigate("/error/config");
      return;
    }
    setKeys(resKey.key);
  };

  useEffect(() => {
    if (!keyUsed) {
      navigate("/error/config");
      return;
    }
    getSombreroKeys();
    // Set attributes on the body tag
    // document.body.setAttribute("data-bs-theme", theme);

    // // Clean up function to remove attributes when the component unmounts
    // return () => {
    //   document.body.setAttribute("data-bs-theme", theme);
    // };
  }, [keyUsed, navigate]);
  return (
    <RootLayout title="Receive" onBackClick={handleBackClick}>
      {keys && <CheckoutSombrero keys={keys} />}
    </RootLayout>
  );
};

export default Sombrero;
