export const CUSTOMER_AUTH_SUCCESS = "CUSTOMER_AUTH_SUCCESS";
export const CUSTOMER_AUTH_FAILURE = "CUSTOMER_AUTH_FAILURE";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE"
export const CUSTOMER_DETAILS_SUCCESS = "CUSTOMER_DETAILS_SUCCESS";
export const CUSTOMER_DETAILS_FAILURE = "CUSTOMER_DETAILS_FAILURE"
export const CREATE_SESSION = "CREATE_SESSION"; 
export const CREATE_SESSION_FAILED = "CREATE_SESSION_FAILED"; 
export const PORTAL_CONFIG_SUCCESS = "PORTAL_CONFIG_SUCCESS";
export const PORTAL_CONFIG_FAILURE = "PORTAL_CONFIG_FAILURE";
export const GENERATE_MUDREX_PAYMENT_LINK = "GENERATE_MUDREX_PAYMENT_LINK";
export const GENERATE_MUDREX_PAYMENT_LINK_FAILURE = "GENERATE_MUDREX_PAYMENT_LINK_FAILURE";
export const GENERATE_MUDREX_KYC_LINK = "GENERATE_MUDREX_KYC_LINK";
export const GENERATE_MUDREX_KYC_LINK_FAILURE = "GENERATE_MUDREX_KYC_LINK_FAILURE";
export const GET_MUDREX_PAYMENT_METHODS = "GET_MUDREX_PAYMENT_METHODS";
export const GET_MUDREX_PAYMENT_METHODS_FAILURE = "GET_MUDREX_PAYMENT_METHODS_FAILURE";
export const GET_MUDREX_PAYMENT_INTENT = "GET_MUDREX_PAYMENT_INTENT";
export const GET_MUDREX_PAYMENT_INTENT_FAILURE = "GET_MUDREX_PAYMENT_INTENT_FAILURE";
export const GET_MUDREX_USER = "GET_MUDREX_USER";
export const GET_MUDREX_USER_FAILURE = "GET_MUDREX_USER_FAILURE";
export const GET_PAYMENT_STATUS = "GET_PAYMENT_STATUS";
export const GET_PAYMENT_STATUS_FAILURE = "GET_PAYMENT_STATUS_FAILURE";

export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
