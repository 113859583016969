import React, { useState, useEffect } from "react";
import { Button } from "antd";
import accounting from "accounting";
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";
import { useNavigate } from "react-router-dom";

const Easebuzz = ({
  publicKey,
  accessToken,
  apiKey,
  paymentId,
  payment,
  theme,
}) => {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(600);
  const [loading, setLoading] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(interval);
          navigate("/error/timeout", { state: payment });
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [payment, navigate]);

  useEffect(() => {
    const asyncFun = async () => {
      const res = await loadScript(
        "https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/v2.0.0/easebuzz-checkout-v2.min.js"
      );
      if (!res) {
        setScriptLoaded(false);
      } else {
        setScriptLoaded(true);
      }
    };
    asyncFun();
  }, []);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let easebuzzCheckout = new window.EasebuzzCheckout(publicKey, "prod");
    const options = {
      access_key: accessToken,
      onResponse: (response) => {
        setLoading(false);
        if (response.status === "success") {
          window.location.assign(
            payment.successCallback + "?paymentId=" + paymentId
          );
        } else {
          window.location.assign(
            payment.failureCallback + "?paymentId=" + paymentId
          );
        }
      },
      theme: "#123456", // color hex
    };
    easebuzzCheckout.initiatePayment(options);
  };

  const handleCancel = () => {
    setLoading(false);
    if (window !== window.parent) {
      window.parent.closeIframe();
    } else {
      window.location.assign(
        payment.failureCallback + "?paymentId=" + payment._id
      );
    }
  };
  return (
    <form onSubmit={handleSubmit} className="w-full flex flex-col items-center justify-start">
      {/* <div className="flex items-center header-left-col">
          <Button
            type="button"
            className="bg-transparent text-black text-2xl p-0 me-3"
            onClick={handleCancel}
          >
            &#8592;
          </Button>
          <h2 className="text-black text-2xl font-semibold">Summary</h2>
        </div> */}
      <div
        className={
          seconds > 10
            ? "timer bg-orange header-right-col"
            : "timer bg-red header-right-col"
        }
      >
        Expires In: {minutes.toString().padStart(2, "0")}:
        {remainingSeconds.toString().padStart(2, "0")}
      </div>
      <div className="main-top-checkout-section-box flex flex-col items-center my-[25px] !pb-[12px]">
        <div className="table-wrapper summary-maintable w-full checkout-summary-table">
          <table className="table w-full">
            <tbody>
              <tr>
                <td className="common-lbl">Date/Time</td>
                <td className="common-lbl text-right">
                  {moment(payment.createdAt).format("LLL")}
                </td>
              </tr>
              <tr>
                <td className="common-lbl">
                  <p className="flex items-center justify-start">
                    Amount{" "}
                    <span className="small-text-lbl ml-1">
                      ({payment?.currency?.toUpperCase()})
                    </span>
                  </p>
                </td>
                <td className="common-lbl text-right">
                  {accounting.formatMoney(payment?.amount, {
                    symbol: getSymbolFromCurrency(
                      payment?.currency?.toUpperCase()
                    ),
                    format: "%s%v",
                  })}
                </td>
              </tr>
              <tr>
                <td className="common-lbl">To</td>
                <td className="common-lbl text-right">
                  {payment?.orgId?.name}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
        <Button
          type="primary"
          htmlType="submit"
          className="bnksy-btn-primary"
          disabled={!scriptLoaded}
          loading={loading}
        >
          Pay
          {accounting.formatMoney(payment?.amount, {
            symbol: getSymbolFromCurrency(payment?.currency?.toUpperCase()),
            format: " %s%v",
          })}
        </Button>
      </div>
    </form>
  );
};

export default Easebuzz;
