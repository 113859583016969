import * as ActionTypes from "../actionTypes"; // Import your action types
import Cookies from "js-cookie";
import { verifyToken } from "../../utils/utils";
import { apiCall } from "../api";

// Action creator to fetch all organizations
export const customerAuth = (data) => async (dispatch) => {
  try {
    // Make the API call to fetch organizations
    const response = await apiCall(`/sdk/customer/auth`, "POST", data);

    const customerData = {
      to: data.to,
      customerID: response.customerID,
    };

    dispatch({
      type: ActionTypes.CUSTOMER_AUTH_SUCCESS,
      payload: customerData,
    });

    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    dispatch({
      type: ActionTypes.CUSTOMER_AUTH_FAILURE,
      payload: error,
    });

    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
};

export const verifyOTP = (data) => async (dispatch) => {
  try {
    // Make the API call to fetch organizations
    const response = await apiCall(`/sdk/customer/verify-otp`, "POST", data);
    dispatch({
      type: ActionTypes.VERIFY_OTP_SUCCESS,
      payload: response,
    });
    const accessToken = response.tokens.access.token;
    const refreshToken = response.tokens.refresh.token;

    const accessTokenExpiry = new Date(
      response.tokens.access.expires
    ).getTime();
    const refreshTokenExpiry = new Date(
      response.tokens.refresh.expires
    ).getTime();

    Cookies.set("customer_access_token", accessToken, {
      expires: new Date(accessTokenExpiry),
    });
    Cookies.set("customer_refresh_token", refreshToken, {
      expires: new Date(refreshTokenExpiry),
    });

    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    dispatch({
      type: ActionTypes.VERIFY_OTP_FAILURE,
      payload: error,
    });

    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
};

export const customerDetails = () => async (dispatch) => {
  try {
    // Make the API call to fetch organizations
    const response = await apiCall(`/sdk/customer/details`, "GET", null);
    dispatch({
      type: ActionTypes.CUSTOMER_DETAILS_SUCCESS,
      payload: response,
    });
    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    dispatch({
      type: ActionTypes.CUSTOMER_DETAILS_FAILURE,
      payload: error,
    });
    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
};

export const createSession = (data,key) => async (dispatch) => {
  try {
    // Make the API call to create session
    const response = await apiCall(`/sdk/kyc/createSession`, "POST", data,key);
    dispatch({
      type: ActionTypes.CREATE_SESSION,
      payload: response,
    });
    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    dispatch({
      type: ActionTypes.CREATE_SESSION_FAILED,
      payload: error,
    });
    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
};
    

export const uploadDocument = (data,key) => async (dispatch) => {
  try {
    // Make the API call to create session
    const response = await apiCall(`/sdk/kyc/uploadDocument`, "POST", data,key);
    dispatch({
      type: ActionTypes.UPLOAD_DOCUMENT,
      payload: response,
    });

    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
}

export const updatePayment = (data,paymentId) => async (dispatch) => {
  try {
    // Make the API call to create session
    const response = await apiCall(`/sdk/kyc/updatePayment/${paymentId}`, "PATCH", data);
    return {
      code: 1,
      data: response,
    };
  } catch (error) {
    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
}

