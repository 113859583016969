import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_API_URL}/sdk`

export const callGetApi = async (key, endpoint, params) => {
    return await axios.get(BASE_URL + endpoint, {
        headers: {
            "X-AUTH": key,
        },
        params,
    });
};

export const callPatchApi = async (key, endpoint, data) => {
    return await axios.patch(BASE_URL + endpoint, data, {
        headers: {
            "X-AUTH": key,
            "Content-Type": "application/json"
        }
    });
};

export const callPostApi = async (key, endpoint, data) => {
    return await axios.post(BASE_URL + endpoint, data, {
        headers: {
            "X-AUTH": key,
            "Content-Type": "application/json"
        }
    });
};