import React from "react";
import RootLayout from "../../component/layout";
import Signin from "../../component/auth/signin";

const signin = () => {
  return (
    <RootLayout>
      <Signin />
    </RootLayout>
  );
};

export default signin;
