import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RootLayout from "../../../component/layout";
import Awaiting from "../../../component/common/Awaiting";

const MudrexKycSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const searchObject = {};
      const searchParams = new URLSearchParams(location.search);
      for (const [key, value] of searchParams.entries()) {
        searchObject[key] = value;
      }
      if (
        !searchObject.hasOwnProperty("key") ||
        !searchObject.hasOwnProperty("paymentId")
      ) {
        navigate("/error/config");
      } else {
         
       navigate(`/?key=${searchObject.key}&paymentId=${searchObject.paymentId}`);
      }
    };
    fetchData();
  }, [location.search, navigate]);
  return (
    <RootLayout>
      <Awaiting/>
    </RootLayout>
  );
};

export default MudrexKycSuccess;
