import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RootLayout from "../../../component/layout";
import { verifyTripleaPayment } from "../../../network/api";
import Awaiting from "../../../component/common/Awaiting";

const TripleASuccess = () => {
  const location = useLocation();
  let { apikey } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const searchObject = {};
      const searchParams = new URLSearchParams(location.search);
      for (const [key, value] of searchParams.entries()) {
        searchObject[key] = value;
      }
      if (
        !searchObject.hasOwnProperty("payment_reference") ||
        !searchObject.hasOwnProperty("status")
      ) {
        navigate("/error/config");
      } else {
        const status = await verifyTripleaPayment(
          apikey,
          searchObject.payment_reference
        );
        if (status?.status === "success") {
          window.location.assign(
            status.successCallback + "?paymentId=" + status._id
          );
        } else if (status?.status === "awaiting" ||  status?.status === "hold") {
          navigate(`/awaiting/triplea`, {
            state: {
              payment_reference: searchObject.payment_reference,
              apikey: apikey,
            },
          });
        } else {
          window.location.assign(
            status.failureCallback + "?paymentId=" + status._id
          );
        }
      }
    };
    fetchData();
  }, [location.search, navigate, apikey]);
  return (
    <RootLayout>
      <Awaiting />
    </RootLayout>
  );
};

export default TripleASuccess;
