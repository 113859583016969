import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  customerDetails,
  verifyOTP,
} from "../../redux/actions/customerActions";
import { Bounce, toast, ToastContainer } from "react-toastify";
import Button from "../common/Button";

const OtpForm = () => {
  const customerData = useSelector((state) => state?.reducer?.customerData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [loadingverify, setLoadingVerify] = useState(false);

  const handleChange = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newOtp = otp.split("");
      newOtp[index] = value;
      setOtp(newOtp.join(""));

      // Move focus to next input
      if (value && index < 3) {
        const nextInput = document.getElementById(`otp-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };


  const handleVerify = async () => {
    if (otp.length < 4 || otp.includes(" ")) {
      setError("Please enter a valid 4-digit OTP.");
      return;
    }
    setError("");

    const data = {
      customerID: customerData.customerID,
      otp,
    };
    setLoadingVerify(true);
    const response = await dispatch(verifyOTP(data));

    if (response.code === 1) {
      // navigate("/checkout");
      const response = await dispatch(customerDetails());
      if (response.code === 1) {
        showSuccessMessage();
        setTimeout(() => {
          if (response.data.kycStatus === "not-initiated" || response.data.kycStatus === "cancelled") {
            navigate("/auth/kyc");
          } else if (response.data.kycStatus === "approved") {
            navigate("/checkout");
          } else {
            navigate("/auth/kyc/status");
          }
        }, [1000]);
      }
    } else {
      showErrorMessage(response?.data)
    }
    setLoadingVerify(false);
  };

  const showErrorMessage = (errMessage) => toast.error(errMessage, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    theme: "colored",
    transition: Bounce,
  });

  const showSuccessMessage = () => toast.success('Signin Successfull!', {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    theme: "colored",
    transition: Bounce,
  });



  return (
    <Fragment>
      <div className="w-full h-full flex flex-col items-center justify-center pb-[75px]">
        <a href="/main/dashboard/" className="logo-link">
          <img
            src="/banksy-black-logo.svg"
            alt="logo"
            className="w-full logo-transparent-white"
          />
        </a>
        <p className="bnksy-page-title mt-8 mb-[12px]">
          Verify your account
        </p>
        <div className="w-[70px] h-[1px] mx-auto my-[25.5px] bg-line-gray otp-small-line"></div>
        <div className="w-full">
          <div className="w-full bnksy-input-group">
            <div className="flex justify-center gap-2">
              {[0, 1, 2, 3].map((index) => (
                <div key={index}>
                  <input
                    type="text"
                    className="bnksy-form-input text-center otp-input-box"
                    required
                    id={`otp-${index}`}
                    maxLength={1}
                    value={otp[index] || ""}
                    onChange={(e) => handleChange(index, e.target.value)}
                  />
                </div>
              ))}
            </div>
            {error && (
              <p className="text-red-600 font-small text-center mt-2">{error}</p>
            )}
          </div>
          <p className="common-lbl text-center mt-[7px]">
            A code has been sent to <br/> <b className="fw-bold">{customerData?.to}{" "}</b><br/>
            <Link to="/auth/signin" className="text-underline">
              <u>Change?</u>
            </Link>
          </p>
          <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
            <Button
              onClick={handleVerify}
              loading={loadingverify}
            >
              Verify
            </Button>
          </div>
          <ToastContainer position="bottom-right" />
        </div>
      </div>
    </Fragment>
  );
};

export default OtpForm;
