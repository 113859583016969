import React, { useState, useEffect } from "react";

import { Button } from "antd";
import accounting from "accounting";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import getSymbolFromCurrency from "currency-symbol-map";
import SidebarSummary from "../common/SidebarSummary";
import { useSelector } from "react-redux";
import { sombreroPaymentConfirmation } from "../../redux/actions/sombreroActions";

const CheckoutForm = ({ keys }) => {
  const navigate = useNavigate();

  const [seconds, setSeconds] = useState(600);
  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useState("dark");
  const [card, setCard] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [cardLoader, setCardLoader] = useState(false);

  const checkoutConfig = useSelector((state) => state?.reducer?.checkoutConfig);
  const portalConfig = useSelector((state) => state?.reducer?.portalConfig);

  useEffect(() => {
    if (!checkoutConfig) {
      navigate("/error/config");
      return;
    }

    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(interval);
          navigate("/error/timeout", { state: checkoutConfig });
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [checkoutConfig, navigate]);

  useEffect(() => {
    const currentUrl = window.location.href;
    const loadSquareScript = () => {
      
      const script = document.createElement('script');
      script.src = currentUrl.includes('sandbox') 
        ? 'https://sandbox.web.squarecdn.com/v1/square.js'
        : 'https://web.squarecdn.com/v1/square.js';
      script.async = true;
      script.onload = () => {
        initializeSquare();
      };
      document.head.appendChild(script);
    };

    const initializeSquare = async () => {
      setCardLoader(true);
      if (card) return;

      try {
        const payments = window.Square.payments(keys?.appId, keys?.locationId);
        const cardInstance = await payments.card();
        await cardInstance.attach("#card-container");
        setCard(cardInstance);
      } catch (e) {
        console.error("Square initialization error:", e);
        setPaymentStatus("Failed to load payment system");
      }
      setCardLoader(false);
    };

    loadSquareScript();
  }, []);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setPaymentStatus("");

    if (!card) {
      setPaymentStatus("Payment system not initialized");
      setLoading(false);
      return;
    }

    try {
      const result = await card.tokenize();
      if (result.status === "OK") {
        const response = await sombreroPaymentConfirmation(
          checkoutConfig?._id,
          checkoutConfig?.keyUsed,
          result.token
        );
        setPaymentStatus("Payment successful");

        if (response?.data?.status === "success") {
          window.location.assign(
            checkoutConfig?.successCallback +
              `?key=${checkoutConfig?.keyUsed}&paymentId=${checkoutConfig?._id}`
          );
        } else {
          window.location.assign(
            checkoutConfig?.failureCallback +
              `?key=${checkoutConfig?.keyUsed}&paymentId=${checkoutConfig?._id}`
          );
        }
      } else {
        let errorMessage = `Payment failed: ${result.status}`;
        if (result.errors) {
          errorMessage += ` - ${JSON.stringify(result.errors)}`;
        }
        setPaymentStatus(errorMessage);
      }
    } catch (e) {
      console.error("Payment error:", e);
      setPaymentStatus("Payment failed");
    }

    setLoading(false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full flex flex-col items-center justify-start main-summary-form-view-wrapper"
    >
      <div
        className={
          seconds > 10
            ? "timer bg-orange header-right-col"
            : "timer bg-red header-right-col"
        }
      >
        Expires In: {minutes.toString().padStart(2, "0")}:
        {remainingSeconds.toString().padStart(2, "0")}
      </div>
      <div className="w-full flex items-center justify-between main-summary-row-wrapper">
        <div className="flex flex-col items-center justify-center main-summary-row-subwrapper">
          <div className="main-top-checkout-section-box flex flex-col items-center my-[25px]">
            <div className="table-wrapper summary-maintable w-full checkout-summary-table">
              <table className="table w-full">
                <tbody>
                  <tr>
                    <td className="common-lbl">Date/Time</td>
                    <td className="common-lbl text-right">
                      {moment(checkoutConfig?.createdAt).format("LLL")}
                    </td>
                  </tr>
                  <tr>
                    <td className="common-lbl">
                      <p className="flex items-center justify-start">
                        Amount{" "}
                        <span className="small-text-lbl ml-1">
                          ({checkoutConfig?.currency?.toUpperCase()})
                        </span>
                      </p>
                    </td>
                    <td className="common-lbl text-right">
                      {accounting.formatMoney(checkoutConfig?.amount, {
                        symbol: getSymbolFromCurrency(
                          checkoutConfig?.currency?.toUpperCase()
                        ),
                        format: "%s%v",
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td className="common-lbl">To</td>
                    <td className="common-lbl text-right">
                      {portalConfig?.to}
                    </td>
                  </tr>
                  {portalConfig?.description && (
                    <tr>
                      <td className="common-lbl border-hidden" colSpan="2">
                        <p className="small-text-lbl !text-left mt-0">
                          {" "}
                          {portalConfig?.description}
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {checkoutConfig?.currency?.toUpperCase() === "INRD" ? (
            <p className="text-white text-left font-regular fw-normal bg-red p-3 rounded-md">
              <b>⚠️ Attention:</b> To proceed with this transaction, please
              ensure that <b>international payments</b> are enabled on your
              card.
            </p>
          ) : null}
          <div className="w-full flex items-center justify-between">
            <div className="flex flex-col items-center justify-center leftside-col-summary px-[20px]">
              <SidebarSummary />
            </div>
            <div className="w-full mb-[110px] summary-payment-element-wrapper">
              <div id="card-container"></div>
              {paymentStatus && (
                <div className="payment-status mt-4 text-center">
                  {paymentStatus}
                </div>
              )}
            </div>
          </div>
          <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px] summary-btn-wrapper">
            <Button
              type="primary"
              htmlType="submit"
              className="bnksy-btn-primary"
              loading={loading}
              style={{
                backgroundColor: `${
                  theme === "light"
                    ? portalConfig[0]?.light
                    : portalConfig[0]?.dark
                }`,
              }}
            >
              Pay
              {accounting.formatMoney(checkoutConfig?.amount, {
                symbol: getSymbolFromCurrency(
                  checkoutConfig?.currency?.toUpperCase()
                ),
                format: " %s%v",
              })}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CheckoutForm;
