import { apiCall } from "../api";


export const sombreroPaymentConfirmation = async (id, apiKey, token) => {
    try {
      const data = {
        paymentId: id,
        ...(token && { sourceId: token }),
      };
      const response = await apiCall(
        `sdk/sombrero/checkout`,
        "POST",
        data,
        apiKey
      );
  
      return {
        code: 1,
        data: response,
      };
    } catch (error) {
      return {
        code: 0,
        data: error || "Network error occurred",
      };
    }
  };