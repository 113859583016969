
import * as ActionTypes from "../actionTypes"; // Import your action types
import Cookies from "js-cookie";
import { verifyToken } from "../../utils/utils";
import { apiCall } from "../api";

// Action creator to fetch all organizations
export const getPortalConfig = (key) => async (dispatch) => {
  try {
    // Make the API call to fetch organizations
    const response = await apiCall(`/sdk/portalConfig`, "GET", null,key);


    dispatch({
      type: ActionTypes.PORTAL_CONFIG_SUCCESS,
      payload: response[0],
    });

    return {
      code: 1,
      data: response[0],
    };
  } catch (error) {
    dispatch({
      type: ActionTypes.PORTAL_CONFIG_FAILURE,
      payload: error,
    });

    return {
      code: 0,
      data: error.message || "Network error occurred",
    };
  }
};