import {thunk} from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducer/reducer";
import socketReducer from './reducer/socketSlice';


const store = configureStore({
  reducer: {
    reducer,
    socket:socketReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;