import Paragraph from 'antd/es/typography/Paragraph'
import Title from 'antd/es/typography/Title'
import React from 'react'
import Button from "../common/Button";

const Awaiting = ({
  imageSource = "/Please-Wait.png",
  title = "Please wait!",
  description = "We are checking your configuration",
  buttonText,
  onButtonClick
}) => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <Title level={1} className="text-center !mb-0 common-lbl text-lbl-para fw-bold font-large error-big-title">
        {title}
      </Title>
      <div className="flex justify-center my-[50px]">
        <img src={imageSource} alt="waiting" className="w-[300px]" />
      </div>
      <Paragraph className="text-center common-lbl !mb-0">
        {description}
      </Paragraph>
      {buttonText && onButtonClick && (
        <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
          <Button
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Awaiting